import {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import useModuleSettings from '../../../global-hooks/useModuleSettings';
import { stylesPropType, vmPropTypes } from '../../../global-prop-types';
import { trackingInit, deleteTracking } from '../model/utils';

const JmeHtml5Player = (props) => {
  const {
    folder2Show,
    directLink,
    playerSettings,
    styles,
    variant,
    playerVariant,
    videoAspectRatio,
    shouldUseId
  } = props;
  const moduleSettings = useModuleSettings('JmeHtml5Player');
  const { id, sessionRecording } = playerSettings ?? {};
  const trackInitCb = useCallback(trackingInit(playerSettings), [
    id,
    sessionRecording
  ]);

  let playerTriesCounter = 0;
  // TODO: // validate that module files are downloaded..
  // TODO: from Chen: Do we destroy player befor loading?

  const playerRef = useRef(null);
  const [isPlayerOn, setIsPlayerOn] = useState(false);
  let currentPlayer;
  let timer;

  const killPlayer = () => {
    if (currentPlayer) {
      try {
        currentPlayer.kill();
        // if (window.PLAYER) {
        //   delete window.PLAYER;
        // }
      } catch (e) {
        console.log('Failed to kill the player', e);
      }
    }
  };

  const basicPathPrefix = ['autoFolder'].includes(playerSettings.exporttype)
    ? 'talks/'
    : 'eposters/';
  const loadPlayer = () => {
    console.log('START currentPlayer - should be empty: ', currentPlayer);
    killPlayer();
    if (moduleSettings) {
      playerTriesCounter += 1;
      const playerObj = {
        baseurl:
          directLink
          || playerSettings.directLink
          || `${moduleSettings.basicPath}${basicPathPrefix}${folder2Show ?? ''}/`,
        ...playerSettings
        // baseurl: 'https://d2t0unnjxes6lt.cloudfront.net/data/ean/data/talks/1100_720803_1/',
      };
      try {
        if (!playerRef.current?.offsetWidth) {
          throw new Error('player offsetWidth is invalid');
        }
        if (typeof window?.jQuery?.fn?.mEventsPlayer !== 'function') {
          throw new Error('jQuery.fn.mEventsPlayer not ready ');
        }
        currentPlayer = window
          .jQuery(playerRef.current)
          ?.mEventsPlayer(playerObj);
        setIsPlayerOn(true);
        // window.PLAYER = currentPlayer;
      } catch (e) {
        setIsPlayerOn(false);
        if (
          playerTriesCounter < 8
          && playerRef.current?.childElementCount === 0
        ) {
          console.log(
            'html5player files are not loaded yet.. or error:',
            e,
            playerTriesCounter
          );
          timer = window.setTimeout(() => {
            console.log('TIMEOUT jmeHtml5Player');
            loadPlayer();
          }, 500);
        } else {
          playerTriesCounter = 0;
          console.error(
            "Coudln't load jquery m-eventPlayer - do reset imports!"
          );
          moduleSettings?.reset();
        }
      }
    }
  };

  useEffect(() => {
    if (!isPlayerOn && playerRef && moduleSettings) {
      loadPlayer();
    }
    return () => {
      window.clearTimeout(timer);
      // killPlayer();
    };
  }, [playerRef, moduleSettings, folder2Show, directLink]);

  useEffect(() => {
    if (playerRef.current && isPlayerOn) {
      trackInitCb.setVideos(document.getElementsByTagName('video'));
      trackInitCb();
    }
  }, [isPlayerOn, trackingInit]);

  useEffect(() => () => {
    deleteTracking(id);
  }, [trackingInit, id]);

  useEffect(
    () => () => {
      clearTimeout(timer);
      killPlayer();
    },
    []
  );
  // Guy's
  // Conditional rendering should be done only after calls to all hooks:
  // Calculation needed to get the aspect-ration controlled width of the player:
  const wrapperHeight = playerRef.current?.clientHeight;
  const mEventsPlayerControlsHeight = 40;
  const width = (videoAspectRatio ?? 16 / 9)
    * (wrapperHeight - mEventsPlayerControlsHeight);

  // Chen's
  // const mEventsPlayerControlsHeight = 50;
  // const width = playerRef?.current?.clientWidth;
  // const height = `${width * (videoAspectRatio ?? 9 / 16) + mEventsPlayerControlsHeight}px`;

  if (!folder2Show && !directLink) return null;
  return (
    <div className={`${variant || ''}`} style={{ width, ...styles?.wrapper }}>
      <div
        id={shouldUseId ? Math.random() : ''}
        className={`${playerVariant || ''}`}
        ref={playerRef}
        style={styles?.player}
      />
    </div>
  );
};

JmeHtml5Player.propTypes = {
  variant: PropTypes.string,
  shouldUseId: PropTypes.bool,
  playerVariant: PropTypes.string,
  styles: stylesPropType,
  folder2Show: PropTypes.string.isRequired,
  directLink: PropTypes.string,
  playerSettings: PropTypes.object,
  videoAspectRatio: PropTypes.number
};
JmeHtml5Player.defaultProps = {
  variant: '',
  playerVariant: '',
  styles: { wrapper: {}, player: {} },
  playerSettings: {}
};

JmeHtml5Player.vmPropTypes = {
  shouldUseId: vmPropTypes.boolean,
  variant: vmPropTypes.className,
  playerVariant: vmPropTypes.className,
  styles: { wrapper: vmPropTypes.styles, player: vmPropTypes.styles },
  folder2Show: vmPropTypes.string,
  directLink: vmPropTypes.string,
  playerSettings: vmPropTypes.object,
  videoAspectRatio: vmPropTypes.number
};

export default JmeHtml5Player;
